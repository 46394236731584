import React, { useEffect, useState } from "react";
import {
  Grid,
  Button,
  Checkbox,
  CircularProgress,
  useMediaQuery,
  Modal,
} from "@mui/material";
import { useAccount, useSigner, useProvider, useNetwork} from 'wagmi'
import {
  traitsMapping,
  traitsMappingReference,
  traitsOrder,
} from "../assets/heroesData/TraitEncodingHeroes";
import sum from "lodash.sum";
import traitCountFile from "../assets/heroesData/TraitToCount_V5_Heroes.json";
import Fade from "@mui/material/Fade";
import { experimentalStyled as styled } from "@mui/material/styles";
import { ethers } from "ethers";
import { Traits, Heroes, MyHeroes } from "../components";
import {
  ANIMEOE_ABI,
  ANIMEOE_ADDRESS,
  ANIMEV2_ABI,
  ANIMEV2_ADDRESS,
} from "../config/configWrapping";
import {
  WRAPPER_ABI,
  WRAPPER_ADDRESS,
  SOULSLOCKER_ABI,
  SOULSLOCKER_ADDRESS,
  STORAGE_ABI,
  STORAGE_ADDRESS,
  MERGER_ABI,
  MERGER_ADDRESS,
} from "../config/configMerging";
import {
  listTokensOfOwner,
  listAnimeSpiritsOfOwner,
  listAnimeSpiritsHeroesOfOwner,
  approveWrapping,
  isWrappingApproved,
  wrapAndWithdraw,
  unwrapSouls,
} from "../helpers/wrappingHelpers";
import {
  listAnimeSpiritsHeroesOfOwner_separate,
  isMergingApproved,
  approveMerging,
  depositSouls,
  setHeroData,
} from "../helpers/mergerHelpers";
const StyledTrait = styled("div")`
  border-radius: 1px;
  border: 1px solid white;
  padding: 2px;
  height: 60px;
`;
const StyledButton = styled(Button)({
  background: "linear-gradient(0deg, #DB3939, #DB3939)",
  height: 62,
  borderRadius: 0,
  minWidth: "320px",
  fontSize: "20px",
  fontWeight: "900",
  fontFamily: "PPNeueMachina",
  lineHeight: "23px",
  letterSpacing: "-.0.02em",
  marginTop: "1%",
  padding: "0px 10px",
});
const StyledBanner = styled("div")`
  border-radius: 0;
  background: linear-gradient(
      119.13deg,
      rgba(150, 104, 34, 0.8) 22.09%,
      rgba(219, 57, 57, 0.25) 67.89%
    ),
    #181818;
  padding: 5px;
  width: 100%;
  font-size: 15px;
`;
const Wrapping = () => {
  //wagmi hooks for account, signer and network
  let { address, isConnected } = useAccount()
  const provider = useProvider()
  const { data: signer, isError, isLoading } = useSigner()
  const {chain} = useNetwork()

  const [isChainSupported, setIsChainSupported] = useState(false)

  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedSouls, setSelectedSouls] = useState([]);
  const [selectedWrappedSouls, setSelectedWrappedSouls] = useState([]);
  //Contracts/
  const [animeOEContract, setAnimeOEContract] = useState(null);
  const [animeV2Contract, setAnimeV2Contract] = useState(null);
  const [wrapperContract, setWrapperContract] = useState(null);
  const [lockerContract, setLockerContract] = useState(null);
  const [storageContract, setStorageContract] = useState(null);
  const [unwrappedSouls, setUnwrappedSouls] = useState([]);
  const [wrappedSouls, setWrappedSouls] = useState([]);
  const [wrappingApproved, setApprovedWrapping] = useState(false);
  const [loadingUnWrapped, setLoadingUnWrapped] = useState(false);
  const [spirits, setSpirits] = useState([]);
  const [heroes, setHeroes] = useState([]);
  const [loadingWrapped, setLoadingWrapped] = useState(false);
  const [disabledSelectAll, setDisableSelectAll] = useState(false);

  const matchesLarge = useMediaQuery("(max-width:1800px)");
  const matchesMedium = useMediaQuery("(max-width:1440px)");
  const matchesSmall = useMediaQuery("(max-width:1024px)");
  const matchesMobile = useMediaQuery("(max-width:600px)");
  const [openModal, setOpenModal] = useState(false);

  const [modalData, setModalData] = useState({});
  const handleOpen = (id) => {
    setModalData({
      id,
    });
    setOpenModal(true);
  };
  const handleClose = () => {
    setOpenModal(false);
  };
  const tabs = [
    {
      title: "Le Anime",
      content: (
        <>
            <div>
              Wrap your Le Anime Open Edition into the new token necessary for
              Hero phase and receive a free Spirit NFT. The new token contains
              both the original video NFT and the Soul image NFT, and will display
              the latter by default.
            </div>
            <div>
              You will be able to swap between the two visuals in the future.
            </div>
            <div>
              We highly recommend to wrap all your Le Anime, as there is only
              benefits in doing so.
            </div>
            Please note that you have to click the below button twice, first to
            approve and then to wrap.
        </>
      ),
    },
    {
      title: "Spirits",
      content: "Spirits NFT currently in your wallet.",
    },
    {
      title: "Heroes",
      content:
        "Heroes currently in your wallet. Use Create New Hero to forge a new Hero. Click on the current Heroes to see or modify them in the editor.",
    },
  ];

  const onSelectTab = (index) => {
    setSelectedTab(index);
  };
  // UNWRAPPED
  const handleSoulSelection = (e, id) => {
    if (e.target.checked) {
      setSelectedSouls([...selectedSouls, id]);
    } else if (e.target.tagName === "IMG" && !selectedSouls.includes(id)) {
      setSelectedSouls([...selectedSouls, id]);
    } else {
      const filteredSelectedSouls = selectedSouls.filter((item) => item !== id);
      setSelectedSouls(filteredSelectedSouls);
    }
  };
  const checkSelected = (id) => {
    const found = selectedSouls.find((item) => item === id);
    return selectedSouls.indexOf(found) !== -1;
  };
  const handleSelectAll = (e) => {
    if (e.target.checked) {
      const ids = unwrappedSouls.map((soul) => soul.id);

      setSelectedSouls(ids);
      return;
    }
    setSelectedSouls([]);
  };
  //WRAPPED
  const handleWrappedSoulSelection = (e, id) => {
    if (e.target.checked) {
      setSelectedWrappedSouls([...selectedWrappedSouls, id]);
    } else if (
      e.target.tagName === "IMG" &&
      !selectedWrappedSouls.includes(id)
    ) {
      setSelectedWrappedSouls([...selectedWrappedSouls, id]);
    } else {
      const filteredSelectedWrappedSouls = selectedWrappedSouls.filter(
        (item) => item !== id
      );
      setSelectedWrappedSouls(filteredSelectedWrappedSouls);
    }
  };
  const checkWrappedSelected = (id) => {
    const found = selectedWrappedSouls.find((item) => item === id);
    return selectedWrappedSouls.indexOf(found) !== -1;
  };
  const approveWrappingHandler = () => {
    approveWrapping(address, animeOEContract)
      .then((res) => {
        setApprovedWrapping(true);
      })
      .catch((err) => {
        setApprovedWrapping(false);
      });
  };
  const refreshTokens = async () => {
    let unwrapped = await listTokensOfOwner(address, animeOEContract);
    unwrapped = unwrapped.sort((a, b) => a.soulNumber - b.soulNumber);

    setUnwrappedSouls(unwrapped);

    let newAnimeTokens = await listAnimeSpiritsHeroesOfOwner_separate(
      address,
      wrapperContract,
      lockerContract
    );

    const heroesIds = newAnimeTokens.heroIDs.map((hero) => hero.soulNumber);

    setHeroes(heroesIds);

    newAnimeTokens = newAnimeTokens.tokenIDs;
    let formattedNewAnimeTokens = newAnimeTokens.map((token) => {
      const newFormat = Array(7)
        .fill(0)
        .map((cell, j) => {
          if (traitsMappingReference[j] === "Runes") {
            const value = traitsMapping[5][token.traits[0][8][0]];
            return {
              trait_type: "Runes",
              value,
              trait_count: sum(traitCountFile["runes"][value]),
            };
          }
          if (traitsMappingReference[j] === "Extra") {
            const value = traitsMapping[6][token.traits[1][0][0]];
            return {
              trait_type: "Extra",
              value,
              trait_count: sum(traitCountFile["extra"][value]),
            };
          }
          const value = traitsMapping[j][token.traits[0][j + 3]];
          return {
            trait_type: traitsMappingReference[j],
            value,
            trait_count:
              traitCountFile[traitsMappingReference[j].toLowerCase()][
                value
              ],
          };
        });
      newFormat.push({
        trait_type: "Spirit",
        value: "Score",
        trait_count: token.traits[0][1],
      });
      return {
        id: token.id,
        soulNumber: token.soulNumber,
        traits: newFormat,
      };
    });
    let onlySpirits = formattedNewAnimeTokens.filter(
      (item) => item.id > 101573
    );
    // order onlySpirits by descending score
    onlySpirits = onlySpirits.sort((a, b) =>  b.traits[7].trait_count - a.traits[7].trait_count);

    setSpirits(onlySpirits);
    let onlySouls = formattedNewAnimeTokens.filter(
      (item) => item.id <= 101573
    );
    // order onlySouls by descending score
    onlySouls = onlySouls.sort((a, b) =>  b.traits[7].trait_count - a.traits[7].trait_count);
    setWrappedSouls(onlySouls);
  };

  const handleWrapAndWithdraw = () => {
    setLoadingUnWrapped(true);
    wrapAndWithdraw(selectedSouls, wrapperContract)
      .then(() => {
        refreshTokens();
        setLoadingUnWrapped(false);
      })
      .catch((err) => setLoadingUnWrapped(false));
  };
  const handleUnWrap = () => {
    setLoadingWrapped(true);
    unwrapSouls(selectedWrappedSouls, wrapperContract)
      .then(() => {
        refreshTokens();
        setLoadingWrapped(false);
      })
      .catch((err) => setLoadingWrapped(false));
  };
  useEffect(() => {
    if (typeof chain !== "undefined" && typeof chain.unsupported !== "undefined") {
      setIsChainSupported(!chain.unsupported)
    }
    // console.log("chain changed", chain)
  }, [chain])
  useEffect(() => {
    // console.log(selectedSouls);
  }, [selectedSouls]);
  useEffect(() => {
    // console.log(selectedWrappedSouls);
  }, [selectedWrappedSouls]);
  useEffect(() => {
    if (signer && isConnected && isChainSupported) {
      const animeOEContract = new ethers.Contract(
        ANIMEOE_ADDRESS,
        ANIMEOE_ABI,
        signer
      );
      setAnimeOEContract(animeOEContract);
      const wrapperContract = new ethers.Contract(
        WRAPPER_ADDRESS,
        WRAPPER_ABI,
        signer
      );
      setWrapperContract(wrapperContract);
      const lockerContract = new ethers.Contract(
        SOULSLOCKER_ADDRESS,
        SOULSLOCKER_ABI,
        signer
      );
      setLockerContract(lockerContract);
      const storageContract = new ethers.Contract(
        STORAGE_ADDRESS,
        STORAGE_ABI,
        signer
      );
      setStorageContract(storageContract);
      const mergeContract = new ethers.Contract(
        MERGER_ADDRESS,
        MERGER_ABI,
        signer
      );
      const animeV2Contract = new ethers.Contract(
        ANIMEV2_ADDRESS,
        ANIMEV2_ABI,
        signer
      );
      setAnimeV2Contract(animeV2Contract);
      const listTokens = async () => {
        let unwrapped = await listTokensOfOwner(
          address,
          animeOEContract
        );
        unwrapped = unwrapped.sort((a, b) => a.soulNumber - b.soulNumber);
        setUnwrappedSouls(unwrapped);
        if (unwrapped.length > 100) {
          setDisableSelectAll(true);
        } else {
          setDisableSelectAll(false);
        }
        const wrappingApproved = await isWrappingApproved(
          address,
          animeOEContract
        );
        setApprovedWrapping(wrappingApproved);

        let newAnimeTokens = await listAnimeSpiritsHeroesOfOwner_separate(
          address,
          wrapperContract,
          lockerContract
        );

        const heroesIds = newAnimeTokens.heroIDs.map((hero) => hero.soulNumber);

        setHeroes(heroesIds);

        newAnimeTokens = newAnimeTokens.tokenIDs;
        let formattedNewAnimeTokens = newAnimeTokens.map((token) => {
          const newFormat = Array(7)
            .fill(0)
            .map((cell, j) => {
              if (traitsMappingReference[j] === "Runes") {
                const value = traitsMapping[5][token.traits[0][8][0]];
                return {
                  trait_type: "Runes",
                  value,
                  trait_count: sum(traitCountFile["runes"][value]),
                };
              }
              if (traitsMappingReference[j] === "Extra") {
                const value = traitsMapping[6][token.traits[1][0][0]];
                return {
                  trait_type: "Extra",
                  value,
                  trait_count: sum(traitCountFile["extra"][value]),
                };
              }
              const value = traitsMapping[j][token.traits[0][j + 3]];
              return {
                trait_type: traitsMappingReference[j],
                value,
                trait_count:
                  traitCountFile[traitsMappingReference[j].toLowerCase()][
                    value
                  ],
              };
            });
          newFormat.push({
            trait_type: "Spirit",
            value: "Score",
            trait_count: token.traits[0][1],
          });
          return {
            id: token.id,
            soulNumber: token.soulNumber,
            traits: newFormat,
          };
        });
        let onlySpirits = formattedNewAnimeTokens.filter(
          (item) => item.id > 101573
        );
        // order onlySpirits by descending score
        onlySpirits = onlySpirits.sort((a, b) =>  b.traits[7].trait_count - a.traits[7].trait_count);

        setSpirits(onlySpirits);
        let onlySouls = formattedNewAnimeTokens.filter(
          (item) => item.id <= 101573
        );
        // order onlySouls by descending score
        onlySouls = onlySouls.sort((a, b) =>  b.traits[7].trait_count - a.traits[7].trait_count);
        setWrappedSouls(onlySouls);
      };
      listTokens();
      onSelectTab(2);
    }
  }, [signer, address, isConnected, isChainSupported]);
  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      style={{ paddingTop: "100px", width: "100%" }}
    >
      <Modal
        disableScrollLock={true}
        style={{ border: "none" }}
        open={openModal}
        onClose={handleClose}
      >
        <Fade in={openModal}>
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "auto",
              height: matchesMedium ? "auto" : "70%",
              background: "#111111",
              display: "flex",
              alignItems: "center",
              borderRadius: "10px",
              outline: "none",
            }}
          >
            <img
              src={`https://leanime.art/heroes/m/${modalData.id}.jpg`}
              style={{
                height: "100%",
                maxWidth: "100%",
              }}
            />
          </div>
        </Fade>
      </Modal>
      {/* TABS SECTION*/}
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        style={{ width: "100%" }}
      >
        {address && isConnected && isChainSupported ? (
        <>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            style={{ width: "100%" }}
          >
            {tabs.map((tab, i) => {
              return (
                <Grid
                  item
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  key={i}
                >
                  <Button
                    variant="text"
                    style={{
                      fontSize: matchesMobile ? "20px" : "32px",
                      fontWeight: "900",
                      fontFamily: "PPNeueMachina",
                      lineHeight: "23px",
                      letterSpacing: "-.0.02em",
                      color: selectedTab === i ? "white" : "grey",
                    }}
                    onClick={() => onSelectTab(i)}
                  >
                    {tab.title}
                  </Button>
                  {i < tabs.length - 1 && (
                    <div
                      style={{
                        fontSize: "32px",
                        fontWeight: 900,
                        display: "inline-block",
                        height: "100%",
                      }}
                    >
                      &#124;
                    </div>
                  )}
                </Grid>
              );
            })}
          </Grid>
          <Grid
            item
            style={{
              width: matchesSmall ? "80%" : "50%",

              margin: "1% 0",
              textAlign: matchesMobile ? "start" : "center",
              fontSize: "16px",
              lineHeight: 1.4,
              fontFamily: "EurostilNormal",
            }}
          >
            {tabs[selectedTab].content}
          </Grid>
          {/* UNWRAPPED SECTION */}
          {selectedTab === 0 && (
            <>
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="start"
                style={{ 
                  width: "80%", 
                  marginTop: matchesMobile ? "10px" : "10px",
                }}
              >
                <div
                  style={{
                    fontSize: matchesMobile ? "20px" : "32px",
                    fontWeight: "900",
                    fontFamily: "PPNeueMachina",
                    letterSpacing: "-.0.02em",
                  }}
                >
                  NOT WRAPPED (ORIGINAL LE ANIME OE)
                </div>

                <div
                  style={{
                    fontSize: matchesMobile ? "16px" : "19px",
                    fontFamily: "EurostilNormal",
                    fontWeight: 100,
                    margin: "5px 0",
                    color: "#ffb631 ",
                  }}
                >
                  YOU HAVE {unwrappedSouls.length} UNWRAPPED{" "}
                  {unwrappedSouls === 1 ? "SOUL" : "SOULS"}
                </div>
                <div
                  style={{
                    marginBottom: "20px",
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  {!wrappingApproved ? (
                    <StyledButton
                        style={{ marginRight: "20px",
                        fontSize: matchesMobile ? "14px" : "20px",
                      }}
                      variant="contained"
                      color="error"
                      onClick={approveWrappingHandler}
                    >
                      APPROVE WRAPPING
                    </StyledButton>
                  ) : (
                    <>
                      <StyledButton
                        style={{ 
                          marginRight: "20px",
                          fontSize: matchesMobile ? "14px" : "20px", 
                        }}
                        onClick={handleWrapAndWithdraw}
                        variant="contained"
                        color="error"
                        disabled={loadingUnWrapped || selectedSouls.length === 0}
                      >
                        {!loadingUnWrapped ? (
                          "WRAP & WITHDRAW SELECTED"
                        ) : (
                          <CircularProgress />
                        )}
                      </StyledButton>

                      <div
                        style={{
                          display: matchesMobile ? "none" : "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Checkbox
                          disabled={disabledSelectAll}
                          onClick={handleSelectAll}
                          style={{ color: disabledSelectAll ? "grey" : "#ffb631 " }}
                        />
                        <div
                          style={{
                            fontSize: "16px",
                            letterSpacing: "-0.02em",
                            fontFamily: "EurostilDemi",
                            margin: "1% 0",
                            color: "white",
                          }}
                        >
                          SELECT ALL
                        </div>{" "}
                      </div>
                    </>
                  )}
                </div>

                {/* Souls */}
                <Grid
                  container
                  spacing={2}
                  direction="row"
                  justifyContent="start"
                  alignItems="center"
                  style={{ width: "100%" }}
                >
                  {unwrappedSouls.map((soul, i) => {
                    const isItemSelected = checkSelected(soul.id);
                    return (
                      <Grid 
                        item xs={matchesMobile ? 6 : matchesMedium ? 3 : 2.4}
                        key={soul.id}
                        >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "100%",
                          }}
                        >
                          <img
                            style={{
                              width: "100%",
                              borderRadius: "1%",
                              cursor: "pointer",
                            }}
                            id={soul.id}
                            src={`https://leanime.art/textures/assets/animelq/${soul.soulNumber}.jpg`}
                            onClick={(event) => {
                              handleSoulSelection(event, soul.id);
                            }}
                          />

                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Checkbox
                              checked={isItemSelected}
                              onClick={(event) => {
                                handleSoulSelection(event, soul.id);
                              }}
                              style={{
                                color: "#ffb631 ",
                              }}
                            />
                            <div
                              style={{
                                fontFamily: "EurostilDemi",
                                fontSize: "15px",
                              }}
                            >
                              #{soul.soulNumber}
                            </div>{" "}
                          </div>
                        </div>
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
              {/* WRAPPED SECTION */}
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="start"
                style={{ 
                  width: "80%", 
                  marginTop: matchesMobile ? "10px" : "0px",
                }}
              >
                <div
                  style={{
                    fontSize: matchesMobile ? "20px" : "32px",
                    fontWeight: "900",
                    fontFamily: "PPNeueMachina",
                    letterSpacing: "-.0.02em",
                  }}
                >
                  WRAPPED LE ANIME
                </div>

                <div
                  style={{
                    fontSize: matchesMobile ? "16px" : "19px",
                    fontFamily: "EurostilNormal",
                    fontWeight: 100,
                    margin: "5px 0",
                    color: "#ffb631 ",
                  }}
                >
                  YOU HAVE {wrappedSouls.length} WRAPPED{" "}
                  {wrappedSouls.length === 1 ? "SOUL" : "SOULS"}
                </div>

                {/* <div style={{ marginBottom: "20px" }}>
                  <StyledButton
                    style={{ marginRight: "20px" }}
                    variant="contained"
                    color="error"
                    onClick={handleUnWrap}
                    disabled={loadingWrapped || selectedWrappedSouls.length === 0}
                  >
                    {!loadingUnWrapped ? "UNWRAP SELECTED" : <CircularProgress />}
                  </StyledButton>
                </div> */}
                {/* Souls */}
                <Grid
                  container
                  spacing={2}
                  direction="row"
                  justifyContent="start"
                  alignItems="center"
                  style={{ width: "100%", marginBottom: "4%" }}
                >
                  {wrappedSouls.map((soul, i) => {
                    return (
                      <Grid 
                        item xs={matchesMobile ? 6 : matchesMedium ? 3 : 2.4}
                        key={i}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "100%",
                            height: "100%",
                          }}
                        >
                          <div
                            style={{
                              width: "100%",
                              minHeight: "120%",
                              borderRadius: "1%",
                              marginBottom: "2%",
                            }}
                          >
                            <img
                              style={{
                                width: "100%",
                                borderRadius: "1%",
                                marginTop: "0",
                                marginBottom: "0%",
                                cursor: "pointer",
                              }}
                              onClick={() => handleOpen(soul.soulNumber)}
                              id={soul.id}
                              src={`https://leanime.art/textures/assets/animelq2/${soul.soulNumber}.jpg`}
                              onMouseOut={(e) => {
                                e.currentTarget.src = `https://leanime.art/textures/assets/animelq2/${soul.soulNumber}.jpg`;
                                e.currentTarget.style.height = "auto";
                                e.currentTarget.style.marginTop = "0";
                                e.currentTarget.style.marginBottom = "0%";
                              }}
                              onMouseOver={(e) => {
                                e.currentTarget.src = `https://leanime.art/heroes/m/${soul.soulNumber}.jpg`;
                                e.currentTarget.style.marginTop = "12%";
                                e.currentTarget.style.marginBottom = "13%";
                              }}
                            />
                          </div>

                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            {/* <Checkbox
                              checked={isItemSelected}
                              onClick={(event) => {
                                handleWrappedSoulSelection(event, soul.id);
                              }}
                              style={{ color: "#ffb631 " }}
                            /> */}

                            <div
                              style={{
                                fontFamily: "EurostilDemi",
                                width: "90%",
                                padding: "5px",
                                background: i % 2 === 0 ? " #2a2a2a" : "#181818",
                                textAlign: "center",
                              }}
                            >
                              {" "}
                              <a
                                href={`https://opensea.io/assets/ethereum/0x03bebcf3d62c1e7465f8a095bfa08a79ca2892a1/${soul.id}`}
                                target="_blank"
                                style={{ textDecoration: "none", color: "white" }}
                              >
                                #{soul.soulNumber}
                              </a>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                width: "90%",
                                flexWrap: "wrap",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                alignItems: "center",
                                textAlign: "center",
                                padding: "5px",
                                background: i % 2 === 0 ? "#181818" : "#2a2a2a",
                              }}
                            >
                              {soul && soul.traits && soul.traits.map((trait, j) => {
                                const percent = (trait.trait_count / 10627) * 100;
                                return (
                                  <div
                                    key={trait.trait_type}
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      width: matchesMobile
                                        ? "100%"
                                        : matchesSmall
                                        ? "100%"
                                        : matchesMedium
                                        ? "40%"
                                        : "43%",
                                      justifyContent: "start",
                                      alignItems: "start",

                                      marginBottom: "3%",
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontSize: matchesSmall
                                          ? "10px"
                                          : matchesMedium
                                          ? "7px"
                                          : "9px",
                                        fontFamily: "EurostilNormal",
                                        fontWeight: 100,
                                        color: " rgba(255,255,255,0.5)",
                                      }}
                                    >
                                      {trait.trait_type.toUpperCase()}
                                    </span>
                                    <span
                                      style={{
                                        fontSize: "10px",
                                        fontFamily: "PPNeueMachina",
                                        textAlign: "left",
                                        lineHeight: "200%",
                                        fontWeight: 900,
                                        width: matchesSmall
                                          ? "120px"
                                          : matchesMedium
                                          ? "70px"
                                          : matchesLarge
                                          ? "90px"
                                          : "100px",
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                      }}
                                    >
                                      {Number(trait.value)
                                        ? trait.value
                                        : trait.value.toUpperCase()}
                                    </span>
                                    <span
                                      style={{
                                        letterSpacing: "-0.02em",
                                        lineHeight: "150%",
                                        fontWeight: "900",
                                        fontFamily: "PPNeueMachina",
                                        fontSize: "15px",
                                        color:
                                          trait.trait_type === "Spirit"
                                            ? "white"
                                            : percent >= 50
                                            ? "#ffffff"
                                            : percent >= 25
                                            ? "#fff0da"
                                            : percent >= 10
                                            ? "#ffe0b5"
                                            : percent >= 5
                                            ? "#ffd191"
                                            : percent >= 1
                                            ? "#ffc26c"
                                            : percent >= 0.1
                                            ? "#ffb444"
                                            : percent > 0
                                            ? "#ffa500"
                                            : "ffffff",
                                      }}
                                    >
                                      {trait.trait_type === "Spirit"
                                        ? trait.trait_count
                                        : percent > 1
                                        ? `${percent.toFixed(0)}%`
                                        : `${percent.toFixed(3)}%`}
                                    </span>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
            </>
          )}
          {selectedTab === 1 && (
            <>
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                style={{ 
                  width: "80%", 
                  marginTop: matchesMobile ? "10px" : "10px",
                }}
              >
                <Traits spirits={spirits} />
              </Grid>
            </>
          )}
          {selectedTab === 2 && (
            <>
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                style={{ width: "80%" }}
              >
                <MyHeroes data={heroes} />
              </Grid>
            </>
          )}
        </> ) : (
          <span
          style={{
            fontSize: matchesMobile ? "20px" : "32px",
            fontFamily: "PPNeueMachina",
            marginTop: "200px",
            fontWeight: "900",
          }}
        >
          Connect wallet or change network
          </span>
        )}
      </Grid>
      
    </Grid>
  );
};

export default Wrapping;
