import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import {
  Auction,
  Wrapping,
  Builder,
  Editor,
  Links,
  Collection,
  Home,
  LevelsInfo,
  About,
  History,
  Map,
  Entities,
  BuilderGuide,
  Library,
  Comic,
  HeroLeaderboard,
  Talanji,
  DemoBuilder,
  ExpRaffle,
  OrdinalsDay,
  ClaimOrdinal,
  ExpEditions,
  Conclave,
  ZenithBurn,
  ZenithMint,
} from "./pages";
import { Header } from "./components";
const Router = () => {
  return (
    <>
      <Header />

      <Routes>
        <Route exact path="/links" element={<Links />} />
        <Route exact path="/history" element={<History />} />
        <Route exact path="/entities" element={<Entities />} />
        <Route exact path="/collection" element={<Collection />} />
        <Route exact path="/wallet" element={<Wrapping />} />
        <Route exact path="/auction" element={<Auction />} />
        <Route exact path="/heroes-leaderboard" element={<HeroLeaderboard />} />
        <Route exact path="/builder" element={<Builder />} />
        <Route exact path="/map" element={<Map />} />
        <Route exact path="/comic" element={<Comic />} />
        <Route exact path="/library" element={<Library />} />
        <Route exact path="/talanji" element={<Talanji />} />
        <Route exact path="/editor" element={<Editor />} />
        <Route exact path="/about" element={<About />} />
        <Route exact path="/levels-info" element={<LevelsInfo />} />
        <Route exact path="/builder-guide" element={<BuilderGuide />} />
        <Route exact path="/demo-builder" element={<DemoBuilder />} />
        <Route exact path="/exp-raffle" element={<ExpRaffle />} />
        <Route exact path="/ordinals-day" element={<OrdinalsDay />} />
        <Route exact path="/claim-ordinal" element={<ClaimOrdinal />} />
        <Route exact path="/exp-editions" element={<ExpEditions />} />
        <Route exact path="/conclave" element={<Conclave />} />
        <Route exact path="/zenith-burn" element={<ZenithBurn />} />
        <Route exact path="/zenith-mint" element={<ZenithMint />} />
        <Route exact path="/" element={<Home />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </>
  );
};

export default Router;
