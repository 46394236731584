import axios from "axios";
import { ethers } from "ethers";

import metadataHeroes from "../assets/heroesData/HeroesMetaData.json";

import {
  ANIMEOE_ABI,
  ANIMEOE_ADDRESS,
  ANIMEV2_ABI,
  ANIMEV2_ADDRESS,
} from "../config/configWrapping";
export const listTokensOfOwner = async (account, tokenContract) => {
  // console.log(await token.name(), 'tokens owned by', account);
  const sentLogs = await tokenContract.queryFilter(
    tokenContract.filters.Transfer(account, null)
  );
  const receivedLogs = await tokenContract.queryFilter(
    tokenContract.filters.Transfer(null, account)
  );

  const logs = sentLogs
    .concat(receivedLogs)
    .sort(
      (a, b) =>
        a.blockNumber - b.blockNumber || a.transactionIndex - b.TransactionIndex
    );

  const owned = new Set();

  for (const log of logs) {
    const { from, to, tokenId } = log.args;

    if (ethers.utils.getAddress(to) == ethers.utils.getAddress(account)) {
      //console.log(to)
      owned.add(tokenId.toString());
    } else if (
      ethers.utils.getAddress(from) == ethers.utils.getAddress(account)
    ) {
      owned.delete(tokenId.toString());
    }
  }

  let tokenIDs = Array.from(owned).map((id) => {
    let offset;

    if (parseInt(id) > 12800010000) {
      offset = 12800010000;
    } else if (parseInt(id) > 100000) {
      offset = 100000;
    }

    const soulNumber = parseInt(id) - offset;
    return {
      id,
      soulNumber,
      image: `https://leanime.art/textures/assets/animelq/${soulNumber}.jpg`,
    };
  });

  return tokenIDs;
};

export const listAnimeSpiritsOfOwner = async (account, tokenContract) => {
  // console.log(await token.name(), 'tokens owned by', account);

  const sentLogs = await tokenContract.queryFilter(
    tokenContract.filters.Transfer(account, null)
  );
  const receivedLogs = await tokenContract.queryFilter(
    tokenContract.filters.Transfer(null, account)
  );

  const logs = sentLogs
    .concat(receivedLogs)
    .sort(
      (a, b) =>
        a.blockNumber - b.blockNumber || a.transactionIndex - b.TransactionIndex
    );
  const owned = new Set();

  for (const log of logs) {
    const { from, to, tokenId } = log.args;

    if (ethers.utils.getAddress(to) == ethers.utils.getAddress(account)) {
      //console.log(to)
      owned.add(tokenId.toString());
    } else if (
      ethers.utils.getAddress(from) == ethers.utils.getAddress(account)
    ) {
      owned.delete(tokenId.toString());
    }
  }

  let tokenIDs = Array.from(owned).map((id) => {
    let offset = 0;

    if (parseInt(id) > 12800010000) {
      offset = 12800010000;
    } else if (parseInt(id) > 100000) {
      offset = 100000;
    }

    // console.log(id);
    const soulNumber = parseInt(id) - offset;
    return {
      id,
      soulNumber,
      metadata: `https://leanime.art/FinalWithCount_V3/${id}`,
    };
    // return {id,soulNumber}
  });

  tokenIDs = await Promise.all(
    tokenIDs.map(async (token) => {
      const metadata = await axios.get(token.metadata);
      token.traits = metadata.data.attributes;
      return token;
    })
  );

  return tokenIDs;
};

export const listAnimeSpiritsHeroesOfOwner = async (account, tokenContract) => {
  // console.log(await token.name(), 'tokens owned by', account);

  const sentLogs = await tokenContract.queryFilter(
    tokenContract.filters.Transfer(account, null)
  );
  const receivedLogs = await tokenContract.queryFilter(
    tokenContract.filters.Transfer(null, account)
  );

  const logs = sentLogs
    .concat(receivedLogs)
    .sort(
      (a, b) =>
        a.blockNumber - b.blockNumber || a.transactionIndex - b.TransactionIndex
    );
  const owned = new Set();

  for (const log of logs) {
    const { from, to, tokenId } = log.args;

    if (ethers.utils.getAddress(to) == ethers.utils.getAddress(account)) {
      //console.log(to)
      owned.add(tokenId.toString());
    } else if (
      ethers.utils.getAddress(from) == ethers.utils.getAddress(account)
    ) {
      owned.delete(tokenId.toString());
    }
  }

  let tokenIDs = Array.from(owned).map((id) => {
    let offset = 0;

    if (parseInt(id) > 12800010000) {
      offset = 12800010000;
    } else if (parseInt(id) > 100000) {
      offset = 100000;
    }

    // console.log(id);
    const soulNumber = parseInt(id) - offset;
    return {
      id,
      soulNumber,
      traits: metadataHeroes[soulNumber - 1],
    };
    // return {id,soulNumber}
  });

  // tokenIDs = await Promise.all(
  //   tokenIDs.map(async (token) => {
  //     const metadata = await axios.get(token.metadata);
  //     token.traits = metadata.data.attributes;
  //     return token;
  //   })
  // );
  // console.log(tokenIDs);
  return tokenIDs;
};

export const isWrappingApproved = async (account, tokenContract) => {
  let approved = await tokenContract.isApprovedForAll(account, ANIMEV2_ADDRESS);
  return approved;
};

export const approveWrapping = async (account, tokenContract) => {
  let setApproval = await tokenContract.setApprovalForAll(
    ANIMEV2_ADDRESS,
    true
  );

  return setApproval.wait();
};

export const wrapAndWithdraw = async (tokenIDs, tokenContract) => {
  let newTokenIDs = Array.from(tokenIDs).map((id) => {
    const newId = parseInt(id) - 12800010000;
    return newId;
  });

  // console.log('NewId', newTokenIDs)

  let wrapping = await tokenContract.wrapTokenBatch(newTokenIDs);

  return wrapping.wait();
};

export const unwrapSouls = async (tokenIDs, tokenContract) => {
  let newTokenIDs = Array.from(tokenIDs).map((id) => {
    const newId = parseInt(id) - 100000;
    return newId;
  });

  let wrapping = await tokenContract.unwrapTokenBatch(newTokenIDs);

  return wrapping.wait();
};

// listTokensOfOwner(account1, animeOEContract)

// listTokensOfOwner(account1, animeV2Contract)

// console.log(await isWrappingApproved(account1, animeOEContract))
// console.log(await approveWrapping(account1, animeOEContract))

// wrapAndWithdraw(['12800010001','12800010002'], animeV2Contract)
