import React, { useState }  from "react";
import { Link } from "react-router-dom";
import { experimentalStyled as styled } from "@mui/material/styles";
import { useMediaQuery } from '@mui/material';
import { Dropdown } from '@mui/base/Dropdown';
import { Menu } from '@mui/base/Menu';
import { MenuButton as BaseMenuButton } from '@mui/base/MenuButton';
import { MenuItem as BaseMenuItem, menuItemClasses } from '@mui/base/MenuItem';

import '../css/dropdown.css';

const LeftDiv = styled("div")({});
const Nav = () => {
  const matchesMedium = useMediaQuery("(max-width:1200px)");


  const [isOpen, setIsOpen] = useState({});

  const handleMouseEnter = (menuName) => {
    setIsOpen({[menuName]: true });
  };

  const handleMouseLeave = (menuName) => {
    setIsOpen({});
  };

  // on click, open the menu if it's closed, close it if it's open
  const handleClick = (menuName) => {
    setIsOpen({[menuName]: true });
  };

  return (
    <LeftDiv
      style={{
        fontSize: matchesMedium ? "13px" : "15px",
        fontWeight: 100,
        fontFamily: "EurostilNormal",
        display: 'flex',
        justifyContent: 'center',
        width: '100%', // make the div take up the full width of the viewport

      }}
    > 
      <div 
            style={{ display: 'inline-block', marginRight: '10px' }} 
            onMouseEnter={() => handleMouseEnter('menu1')}
            onMouseLeave={() => handleMouseLeave('menu1')}
            onMouseDown={() => handleClick('menu1')}
          >
            {"COLLECTION"}
            {isOpen['menu1'] && (
              <div className="dropdown-menu">
                <a href="/#/collection">COLLECTION</a>
                <a href="/#/entities">ENTITIES</a>
                <a href="/#/heroes-leaderboard">LEADERBOARD</a>
              </div>
            )}
      </div>
      <div 
            style={{ display: 'inline-block', marginRight: '10px' }} 
          >
            {"/"}
      </div>
      <div 
            style={{ display: 'inline-block', marginRight: '10px' }}>    
      <Link style={{ textDecoration: "none", color: "white" }} to="/wallet">
        MY NFTS{" "}
      </Link>
      </div>  
      <div 
            style={{ display: 'inline-block', marginRight: '10px' }} 
          >
            {"/"}
      </div>
      <div 
            style={{ display: 'inline-block', marginRight: '10px' }}>    
      <Link style={{ textDecoration: "none", color: "white" }} to="/builder">
        BUILDER{" "}
      </Link>
      </div> 
      <div 
            style={{ display: 'inline-block', marginRight: '10px' }} 
          >
            {"/"}
      </div> 
      <div 
            style={{ display: 'inline-block', marginRight: '10px' }} 
            onMouseEnter={() => handleMouseEnter('menu2')}
            onMouseLeave={() => handleMouseLeave('menu2')}
            onMouseDown={() => handleClick('menu2')}
          >
            {"LORE"}
            {isOpen['menu2'] && (
              <div className="dropdown-menu">
                <a href="/#/comic">COMIC</a>
                <a href="/#/history">HISTORY</a>
                <a href="/#/map">WORLD</a>
                <a target="_blank" href="https://leanime.art/phase1">INFERNO</a>
                <a target="_blank" href="https://leanime.art/thelab">INTERACTIVE LAB</a>
                <a href="/#/library">LIBRARY</a>
                <a href="/#/talanji">TALANJI</a>
                <a href="/#/ordinals-day">ORDINAL DAY</a>
                <a href="/#/conclave">THE CONCLAVE</a>
              </div>
            )}
      </div>
      <div 
            style={{ display: 'inline-block', marginRight: '10px' }} 
          >
            {"/"}
      </div>
      <div 
            style={{ display: 'inline-block', marginRight: '10px' }} 
            onMouseEnter={() => handleMouseEnter('menu3')}
            onMouseLeave={() => handleMouseLeave('menu3')}
            onMouseDown={() => handleClick('menu3')}
          >
            {"INFO"}
            {isOpen['menu3'] && (
              <div className="dropdown-menu">
                <a target="_blank" href="https://leanime.gitbook.io/le-anime/features-guides/collectors-faqs-tldr">BECOME A COLLECTOR</a>
                <a href="/#/links">LINKS</a>
              </div>
            )}
      </div>
      {/* <div 
            style={{ display: 'inline-block', marginRight: '10px' }} 
          >
            {"/"}
      </div>
      <div 
            style={{ display: 'inline-block', marginRight: '10px' }} 
          >
            <Link style={{ textDecoration: "none", color: "white" }} to="/heroes-leaderboard">
              LEADERBOARD{" "}
            </Link>
      </div>
       */}
    </LeftDiv>
  );
};

export default Nav;
