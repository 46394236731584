import React, { useState, useRef, useEffect } from "react";

import { Grid, useMediaQuery, Modal } from "@mui/material";

import { Link } from "react-router-dom";

import Fade from "@mui/material/Fade";
import banner from "../assets/comic/banner.jpg";
import cover from "../assets/comic/COMIC-COVER_V2.png";
import arrow from "../assets/libraryArrow.png";
import images from "../assets/comic";
import {
  LazyLoadImage,
  trackWindowScroll,
} from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const Comic = ({ scrollPosition }) => {
  const matchesLarge = useMediaQuery("(max-width:1600px)");
  const matchesMedium = useMediaQuery("(max-width:1400px)");
  const matchesSmall = useMediaQuery("(max-width:1200px)");
  const matchesMobile = useMediaQuery("(max-width:800px)");

  const [page, setPage] = useState(1);
  const [openModal, setOpenModal] = useState(false);

  const [modalData, setModalData] = useState(undefined);
  const previousPage = () => {
    setPage((prevPage) => prevPage - 1);
  };
  const nextPage = () => {
    setPage((prevPage) => prevPage + 1);
  };
  // const preloadImage = (src) => {
  //   return new Promise((resolve, reject) => {
  //     let tmp = new Image();

  //     tmp.onload = resolve;
  //     tmp.onerror = reject;
  //     tmp.src = src;
  //   });
  // };

  // const comicRef = useRef([]);
  // useEffect(() => {
  //   (async () => {
  //     if (comicRef.current.includes(page)) {
  //       return;
  //     }
  //     await preloadImage(images[`com${page}`]);
  //     page !== 12 && (await preloadImage(images[`com${page + 1}`]));
  //     comicRef.current[page] = page;
  //   })();
  //   console.log("PAGE", page);
  // }, [page]);

  const handleClose = () => {
    setOpenModal(false);
  };
  const handleOpen = (index) => {
    setModalData(index);
    setOpenModal(true);
  };
  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      style={{
        paddingTop: "100px",
        marginBottom: "30px",
        width: "100%",
        fontFamily: "EurostilNormal",
        fontWeight: "100",
      }}
    >
      {/* fake loading */}

      {Object.keys(images).map((src) => {
        return (
          <img
            src={images[src]}
            style={{ visibility: "hidden", display: "none" }}
          />
        );
      })}
      <Modal
        disableScrollLock={true}
        style={{ border: "none" }}
        open={openModal}
        onClose={handleClose}
      >
        <Fade in={openModal}>
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "90vw",
              maxWidth: "1600px",
              height: matchesLarge ? "auto" : "90%",
              maxHeight: "90%",
              background: "#111111",
              display: "flex",
              alignItems: "center",
              borderRadius: "10px",
              outline: "none",
            }}
          >
            <img
              src={images[`com${modalData + 1}`]}
              style={{
                height: "100%",
                width: "100%",
              }}
            />
          </div>
        </Fade>
      </Modal>
      {/* <div
        style={{
          width: matchesSmall ? "90%" : "70%",
          marginBottom: "20px",
        }}
      >
        <img style={{ width: "100%" }} src={banner} />
      </div> */}
      <div
        style={{
          width: matchesSmall ? "90%" : "35%",
          textAlign: matchesSmall ? "center" : "center",
          marginBottom: "20px",
        }}
      >
        {" "}
        <span
          style={{
            fontFamily: "PPNeueMachina",
            fontSize: matchesSmall ? "18px" : "32px",
            color: "#ffb631",
          }}
        >
          Le Anime Comic [VOL 1]
        </span>
      </div>
      <div
        style={{
          width: matchesSmall ? "90%" : "35%",
          textAlign: matchesSmall ? "center" : "center",
          marginBottom: "20px",
        }}
      >
        {" "}
        <span
          style={{
            letterSpacing: 2,
            fontFamily: "EurostilNormal",
            fontWeight: 100,
            color: "white",
          }}
        >
          Collectors received the Comic as an <Link style={{
                  textDecoration: "none",
                  color: "rgb(255, 182, 49)",
                  cursor: "pointer",
                }}
                target="_blank"
                to="https://opensea.io/collection/le-anime-comic">NFT</Link>!
        </span>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        {matchesMobile && (
          <>
            <LazyLoadImage
              key={"cover"}
              style={{
                marginBottom: "10px",

                width: "100%",
              }}
              width="100%"
              height="auto"
              effect="blur"
              src={cover}
            />

            {Object.keys(images).map((index, key) => {
              return (
                <LazyLoadImage
                  key={key}
                  style={{
                    marginBottom: "10px",
                    cursor: "pointer",
                    width: "100%",
                  }}
                  scrollPosition={scrollPosition}
                  width="90%"
                  height="auto"
                  effect="blur"
                  onClick={() => {
                    handleOpen(key);
                  }}
                  src={images[index]}
                />
              );
            })}
          </>
        )}
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {page > 1 && !matchesMobile && (
          <img
            style={{
              height: matchesSmall ? "30px" : "50px",
              transform: "rotate(180deg)",
              cursor: page > 1 ? "pointer" : "not-allowed",
            }}
            src={arrow}
            onClick={previousPage}
          />
        )}
        {page === 1 && !matchesMobile && (
          <LazyLoadImage
            style={{
              width: "100%",
              maxHeight: "100vh",
            }}
            width={
              matchesMobile
                ? "80%"
                : matchesMedium
                ? "50%"
                : matchesLarge
                ? "40%"
                : "40%"
            }
            effect="blur"
            src={cover}
          />
        )}
        {page > 1 && !matchesMobile && (
          <LazyLoadImage
            style={{
              width: "98%",
              maxHeight: "800px",
              cursor: "pointer",
              margin: "0 10px",
            }}
            width="70%"
            effect="blur"
            onClick={() => handleOpen(page - 2)}
            src={images[`com${page - 1}`]}
          />
        )}
        {page <= Object.keys(images).length && !matchesMobile && (
          <img
            style={{
              height: matchesSmall ? "30px" : "50px",
              cursor: "pointer",
            }}
            src={arrow}
            onClick={nextPage}
          />
        )}{" "}
      </div>
    </Grid>
  );
};
export default trackWindowScroll(Comic);
